<template>
	<div class="reply">
		<el-dialog :title="$t('JUBAO')" :visible.sync="show" width="30%"  @close="close" >
			<span>{{$t('QSRJBLY')}} </span>
			<el-input v-model="content" id="plbox2" class="input_box" :placeholder="$t('QSR')"></el-input>
			<div class="input_add">
				<el-button type="primary" @click="jubao"><!-- 举报 -->{{$t('JUBAO')}}</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	
	export default {
		
		data() {
			return {
				show:false,
				content:'',
				trend_id:''
			};
		},
		// props:{
			
			
		// 	trend_id:{type:Number,default:0},
		// },
		methods: {
			open(id){
				this.trend_id=id;
				this.show=true;
				this.content='';
				this.$emit('open');
			},
			close(){
				this.show=false;
				this.$emit('close');
				//console.log('close');
			},
		
			async jubao(){
				let res=await this.$request.post('/api/trend/tip',{trend_id:this.trend_id,tip_reason:this.content});
				this.$message({
					message:res.message,
					type:'success'
				});
				this.$emit('send');
				this.close();
			},
		}
	};
</script>

<style lang="less" scoped>
	.reply {
		.el-dialog{
			background-color: #F3F1EA;
		}
		
		.input_box {
			width: 100%;
			height: 38px;
			background: #FFFFFF;
			border-radius: 9px;
			margin: 15px 0;
			box-sizing: border-box;
			font-size: 14px;
		}

		.input_add {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 42px;

			.biaoqin {
				width: 25px;
				height: 25px;
			}

			.el-button {
				background-color: #567BB6;
				border: none;
			}
		}
	}
</style>
